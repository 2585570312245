import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BookDemo from "src/Model/BookDemo";
import "src/Styles/navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";



function Navbar() {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [mobiledShow, setMobileShow] = useState(false);
  return (
    <>
      <div className="branches_navbar_main">
        <div className="container-fluid">
          <div className="row d-md-flex d-none">
            <div className="col-lg-2 col-md-2 d-flex align-items-center "
              onClick={() => navigation("/")}
            >
              <img src="/images/logo_colored_new.png" alt="logo" />
            </div>
            <div className="col-lg-6 col-md-7 col-3   d-flex align-items-center ">
              <ul className="p-0 m-0 d-flex navbar_middle_lists">
                <li
                  className="company-dropdown"
                  onClick={() => setShowServices(!show)}
                  onMouseEnter={() => setShowServices(true)}
                  onMouseLeave={() => setShowServices(false)}
                >Solutions
                  {showServices ? (
                    <>
                      <div className="company-box">
                        <ul>
                          <li >
                            <a href="/whiteglove" >White Glove</a>
                          </li>
                          <li>
                            <a href="/lastmile" >Last Mile</a>
                          </li>
                          <li>
                            <a href="/fulfillment" >Fullfillment</a>
                          </li>
                          <li>
                            <a href="/stake-bed" >Stake Bed</a>
                          </li>
                          <li>
                            <a href="/Tours" >Tours</a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </li>
             
                <li
                  className="company-dropdown"
                  onClick={() => setShow(!show)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  Company
                  {show ? (
                    <>
                      <div className="company-box">
                        <ul>
                          <li onClick={() => navigation("/about-us")}>About Us</li>
                          <li onClick={() => navigation("/values")}>
                            Our Values
                          </li>
                          <li onClick={() => navigation("/our-difference")}>
                            Our Difference
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </li>
                <li><a href="/contact-us" >Contact Us</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-3 col-4 d-flex align-items-center justify-content-end ">
              {/* <button
                className="branchesglobalnetworks_global_button btn-box"
                style={{ position: "relative" }}
                onClick={() => setOpen(true)}
              >
                Book Your Appointment <div className="mt-button-shadow"></div>
              </button> */}
            </div>
          </div>
          <BookDemo open={open} setOpen={setOpen} />
        </div>
      </div>
      {/* mobile navbar */}
      <div className="moblie-navbar-box">
        <div className="mobile-log">
          <img src="/images/logo_colored_new.png" alt="mobile-logo" onClick={() => navigation("/")} />
        </div>
        <div className="mobile-icons">
          <GiHamburgerMenu onClick={() => setMobileShow(!mobiledShow)} />
        </div>
      </div>
      {mobiledShow ? (
        <div className="mobile-navbar-content">
          <div className="mobile-navbar-heading">
            <img src="/images/mobilelogo.png" alt="mobile-logo" onClick={() => navigation("/")} />

            <IoMdClose onClick={() => setMobileShow(!mobiledShow)} />
          </div>
          <div className="mobile-navbar-line-box"></div>
          <div className="mobile-navbar-sidebar" onClick={() => navigation("/")}>
            <ul>
              <li><a href="">Solutions</a></li>
              <li> <a href="">Fleet verticales</a></li>

              <li> <a href="">Resources</a></li>
              <li>
                <a href="/about-us">About Us</a> </li>
              <li><a href="/contact-us">Contact Us</a></li>

              <li><a href="/carrier">Carrier</a></li>
              {/* <li  onClick={() => setOpen(true)}>Request a quotes</li> */}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Navbar;
