import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "src/Styles/services.css";

function Services() {
  const navigation = useNavigate();

  const [hoveredItem, setHoveredItem] = useState(null);

  const handleHover = (service) => {
    setHoveredItem(service);
  };

  const handleLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div>
      <div className="branches_home_service_main">
        <div className="container">
          <div className="text-center">
            <h2>Solutions</h2>
            <div className="branches_home_service_line_heading"></div>
          </div>
          <ul className="p-0 mt-4 d-flex justify-content-between align-items-center">
            <li onClick={() => navigation("/whiteglove")}>
            <img src="/carImages/1.png" alt="image" />
              <video
                className="video"
                autoPlay
                width={"100%"}
                // height={200}
                loop
                muted
                playsInline
              >
                <source src="videos/white_glove.mp4" type="video/mp4" />
              </video>
              <p>White Glove</p>
            </li>
            <li
              onClick={() => {
                navigation("/lastmile");
                window.scrollTo(0, 0);
              }}
            >
              <img src="/images/service3.png" alt="image" />
              <video
                className="video"
                autoPlay
                width={"100%"}
                // height={200}
                loop
                muted
                playsInline
              >
                <source src="/videos/last_mile_video.mp4" type="video/mp4" />
              </video>
              <p>Last Mile</p>
            </li>
            <li
              onClick={() => {
                navigation("/fulfillment");
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              <img src="/carImages/2.png" alt="image" />
              <video
                className="video"
                autoPlay
                width={"100%"}
                // height={200}
                loop
                muted
                playsInline
              >
                <source src="/videos/fullfilment.mp4" type="video/mp4" />
              </video>
              <p>Fulfillment</p>
            </li>
            <li
              onClick={() => {
                navigation("/stake-bed");
                window.scrollTo(0, 0);
              }}
            >
              <img src="/carImages/stake_image.jpg" alt="image" />
              <video
                className="video"
                autoPlay
                width={"100%"}
                // height={200}
                loop
                muted
                playsInline
              >
                <source src="/videos/stake-bed.mp4" type="video/mp4" />
              </video>
              <p>Stake Bed</p>
            </li>
            <li
              onClick={() => {
                navigation("/Tours");
                window.scrollTo(0, 0);
              }}
            >
              <img src="/ecommerce_car.png" alt="image" />
              <video
                className="video"
                autoPlay
                width={"100%"}
                // height={200}
                loop
                muted
                playsInline
              >
                <source src="/videos/ecommersVideo.mp4" type="video/mp4" />
              </video>
              <p>Tours</p>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;
