import React,{useEffect} from "react";
import "src/Styles/DropShip/DropShipOptimazation.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


const DropShipOptimazation = () => {

  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <>
      <div className="stack_bed_main">
        <div className="container">
          <div className="row d-flex flex-wrap justify-content-center align-items-start stack_bed_main_left_section">
            <div className="col-12 col-md-6 stack_bed_main_first">
              <h2 className="m-0" >Proven Best In </h2>
              <h2>Class Optimization</h2>
              <h6 className="mt-3" >Our advanced optimization algorithm maximizes fleet performance for stake bed services, enhancing efficiency and improving customer experience with best-in-class solutions</h6>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
               
                <div className="col-12 col-md-6 mt-3">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/1.png" alt="" />
                    <h2> Optimized Dispatching</h2>
                    <p>Our stake bed service provides versatile transportation for large or palletized goods, ensuring efficient and secure deliveries to meet diverse customer needs</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3 stake_bed_relative_box">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/2.png" alt="" />
                    <h2>Optimized Routing</h2>
                    <p>Real-time responsive dispatching for stake bed trucks, accommodating any order, or driver constraint, offering endless configurable business solutions.</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/3.png" alt="" />
                    <h2> Demand Prediction And Rebalancing</h2>
                    <p>Intelligent demand prediction for stake bed truck services across all channels, directing trucks to areas of future demand to maximize revenues and minimize empty miles</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3 stake_bed_relative_box">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/4.png" alt="" />
                    <h2>Dynamic Pricing</h2>
                    <p>Optimize pricing based on multiple stops, flexibility, and various delivery attributes such as pickup and drop-off locations, supply and demand ratios, and customizable business and marketing policies</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/5.png" alt="" />
                    <h2>Dynamic Pooling</h2>
                    <p>Increase fleet efficiency by pooling multiple deliveries in a single stake bed truck—boosting revenue while minimizing travel time.</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-3 stake_bed_relative_box">
                  <div className="stack_bed_main_second_box">
                    <img src="/stack_images/6.png" alt="" />
                    <h2> Future Order Planning and Real-Time Re-optimization</h2>
                    <p>Enhanced communication and planning for future orders through our company app, which adapts to predicted traffic conditions and dispatches optimal strategies.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="stack_bed_control_center_main">
          <div className="container">
             <div className="row d-flex justify-content-center align-items-center">
                <div  data-aos="zoom-in-up" className="col-12 col-md-6">
                    <h2>Control Center</h2>
                    <p>Offering real-time visibility and accountability. Unparalleled fleet and driver governance, including live tracking of vehicles, drivers, and tasks</p>
                </div>
                <div  data-aos="zoom-in-up" className="col-12 col-md-6">
                   <img src="/stack_images/stack_control.png" alt="image" />
                </div>
             </div>
          </div>
      </div>

      
      <div className="stack_bed_driver_app_main">
          <div className="container">
             <div className="row d-flex justify-content-center align-items-center stake_bed_last_section_mobile_responsive">
             <div data-aos="zoom-in-up" className="col-12 col-md-6">
                   <img src="/images/middle_section_image4.png" alt="image" />
                </div>
                <div  data-aos="zoom-in-up" className="col-12 col-md-6">
                    <h2>Driver App</h2>
                    <p>Hands-off management and real-time driver routing, navigation and issue reporting</p>
                </div>
           
             </div>
          </div>
      </div>
    </>
  );
};

export default DropShipOptimazation;
