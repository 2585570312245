import React, { useEffect } from "react";
import "src/Styles/Ecommers/EcommerceOptimazation.css";
import AOS from "aos";
import "aos/dist/aos.css";

const EcommerceOptimazation = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return (
    <>
      <div className="EcommerceOptimazation">
        <div className="container">
          <div className="service-optimazation-heading">
            <h2>Optimize Your Fleet Management</h2>
          </div>
          <div className="service-optimazation-section">
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img src="/serviceImages/meter_image.png" alt="logo" />
                <h6>Reduce Fleet Downtime</h6>
                <p>
                  Reducing fleet downtime is key to operational efficiency and
                  cost savings. Regular, proactive truck maintenance prevents
                  major issues by addressing problems early, minimizing
                  unexpected breakdowns. This keeps vehicles in optimal
                  condition, extends their lifespan, and ensures the fleet runs
                  smoothly and reliably.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img src="/serviceImages/clock_image.png" alt="logo" />
                <h6>Improve customer experience</h6>
                <p>
                  Leverage data to boost customer satisfaction by improving
                  availability, performance, and responsiveness. Use advanced
                  analytics to predict and meet customer needs, ensuring
                  services are always accessible and optimized. Promptly address
                  customer inquiries and feedback to create a reliable and
                  satisfying experience, building trust and loyalty.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img src="/serviceImages/graph_image.png" alt="logo" />
                <h6>Increase Utilization</h6>
                <p>
                  Keep vehicles moving and ensure fast delivery with predictive
                  demand, automation, and operational efficiency. Use data
                  analytics to forecast demand and allocate resources
                  effectively. Streamline processes through automation to
                  enhance productivity and reduce errors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="branches_middle_section_first_main">
        <div className="container">
          <div className="row py-5 ">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Rebalancing</h2>
                <p>
                Increase conversion rate and maximize rebalancing ROI by predicting demand, calculating optimal rebalancing moves and automatically communicating them to driver and field agents
                </p>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img src="/serviceImages/fullmiddleservice7.png" alt="section-first" />
            </div>
          </div>

          <div className="row py-5 ">
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img src="/serviceImages/ecommerce-service_1.png" alt="section-first" />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Cleaning & Servicing</h2>
                <p>
                  Clean and service vehicles that actually need to be cleaned
                  rather than relying on static intervals
                </p>
              </div>
            </div>
          </div>


          <div className="row py-5 ">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Charging</h2>
                <p>
                Optimize your EV fleet with automated vehicle selection for charging, optimized for both charge level, future demand and optimal charging station location and availability
                </p>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img src="/serviceImages/fullmiddleservice6.png" alt="section-first" />
            </div>
          </div>


          <div className="row py-5 ">
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img src="/serviceImages/6.png" alt="section-first" />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Delivery & Collection</h2>
                <p>
                Automatically assign resources (vehicles and drivers) for efficient and just-in-time delivery and collection activities
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default EcommerceOptimazation;
