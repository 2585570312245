import React from "react";
import "src/Styles/OurDefrences/Derfrencesbanner.css";

const DefrencesBanner = () => {
  return (
    <>
      <div className="OurDifference">
        <div className="DefrencesBanner">
          <div data-aos="zoom-in-up" className="banner_content">
            <h1 >Our Difference</h1>
            <p>
          
            </p>
          </div>
        </div>
        <div className="container">
          <div className="service_card_section_one">
            <h2>
              <span>OUR </span> DIFFERENCE
            </h2>
            <div className="row d-flex align-items-center">
              <div className="col-md-5">
                <div  data-aos="zoom-in-up" className="image d-flex justify-content-center">
                  <img src="/images/home_first_image.png" alt="image" />
                </div>
              </div>
              <div className="col-md-7">
                <div  data-aos="zoom-in-up" className="card_content">
                  <h3>Service & Flexibility</h3>
                  <p>
                    At Branches Global Networks, we recognize that every fleet
                    is unique, much like a fingerprint. Each fleet has its own
                    specific goals and challenges that necessitate personalized
                    solutions. Rather than offering generic recomme ndations, we
                    take the time to understand your organization in-depth – its
                    size, objectives, history, and risks. By doing so, we create
                    a tailored program that delivers precisely what you need,
                    ensuring the best possible outcomes and satisfaction
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-md-7">
                <div  data-aos="zoom-in-up" className="card_content">
                  <h3>Innovation</h3>
                  <p>
                    Fleet innovation is our expertise. As a genuine partner, not
                    merely a vendor, we work closely with you to design the most
                    agile and efficient fleet possible. We offer continuous
                    support and consulting expertise to help you achieve your
                    goals. Our creativity and responsiveness distin guish us,
                    ensuring you experience the advantages of our innovative
                    approach every day.
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div  data-aos="zoom-in-up" className="image d-flex justify-content-center">
                  <img src="/images/home_third_image.png" alt="image" />
                </div>
              </div>
            </div>
          </div>

          <div className="service_card_section_two">
            <h2>
              <span>OUR </span> DIFFERENCE
            </h2>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="card">
                  <div className="image">
                    <img src="/images/our_value1.png" alt="img" />
                  </div>
                  <h3>Customer Success</h3>
                  <p>
                    We are dedicated to advancing our customers' success. By
                    understanding their unique needs and challenges, we provide
                    innovative solutions that propel their businesses forward.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="card">
                  <div className="image">
                    <img src="/images/our_value2.png" alt="img" />
                  </div>
                  <h3>Succeed Together</h3>
                  <p>
                    We continuously strive to exceed expectations and push the
                    boundaries of what's possible. Through innovation and a
                    relentless pursuit of excellence, we aim to deliver out
                    standing results
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="card">
                  <div className="image">
                    <img src="/images/our_value3.png" alt="img" />
                  </div>
                  <h3>Exceeding Boundaries</h3>
                  <p>
                    Collaboration and teamwork are at the heart of our approach.
                    We believe in working closely with our clients and partners
                    to achieve shared goals and celebrate collective successes.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="card">
                  <div className="image">
                    <img src="/images/our_value4.png" alt="img" />
                  </div>
                  <h3>Deliver on Promises</h3>
                  <p>
                    Integrity and accountability are paramount. We keep our
                    promises and ensure that our actions consistently reflect
                    our words, building trust with our clients and partners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefrencesBanner;
