import React from "react";
import { NavLink } from "react-router-dom";
import "src/Styles/Carreier/Careersbanner.css";
import { MdKeyboardArrowRight } from "react-icons/md";
const Carrierbanner = () => {
  return (
    <>
      <div className="branches-career-main">
        <div className="branches-career-main-box">
          <div className="branches-career-banner-left-side">
            <h1>Reimagining The Future OF Mobility</h1>
          </div>
          <div className="branches-career-banner-right-side">
            <p>
              We believe in a future without personal vehicles, a future in
              which&nbsp;moving people or goods is quick, efficient and
              affordable.At Autofleet, we work hard to make that future
              start&nbsp;today!
            </p>
            <NavLink>Explore available opportunities <MdKeyboardArrowRight /></NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carrierbanner;
