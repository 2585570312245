import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "src/Styles/Fulfillment/FulfillmentSlider.css";

const FulfillmentSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: (index) => setCurrentSlide(index),
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <FaArrowRight
        className="FulfillmentSlider_Arrowr_right"
        onClick={onClick}
      />
    );
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <FaArrowLeft
        className="FulfillmentSlider_Arrowr_left"
        onClick={onClick}
      />
    );
  };

  const images = [
    "/serviceImages/fulfillment_slider_img1.png",
    "/serviceImages/fulfillment_slider_img2.png",
  ];

  const slideData = [
    "Your consignment is delivered straight from our logistics center to the recipient with no intermediate stops. Our direct delivery method ensures swift handling of your order, saving you time and reducing costs",
    "Our best-in-class trucking service handles both small and large loads with care. Experienced drivers ensure your products are checked and transported efficiently. With frequent departures and direct transport connections, we guarantee swift and reliable distribution"
  ];
  return (
    <>
      <div className="FulfillmentSlider">
        <div className="row">
          <div className="col-md-6">
            <Slider
              {...settings}
              nextArrow={<CustomNextArrow />}
              prevArrow={<CustomPrevArrow />}
            >
              {images.map((image, i) => (
                <>
                  <div>
                    <img src={image} alt="image" />
                  </div>
                </>
              ))}
            </Slider>
            <p className="mt-4 fw-bold">
              {currentSlide + 1} / <span>2</span>
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <h2>Logistic service at its best</h2>
              <p>{slideData[currentSlide]}</p>
             
            </div>
          </div>
        </div>
      </div>

      
 <div className="branches-service-bottom-main">
            <div className="container">
              <div className="branches-service-bottom-section">
                <img src="/stack_images/stake_bottom_image.png" alt="bottom" />
                <div className="branches-about-bottom-content">
                  <h3> Join Us In Building The Future Of Mobility</h3>
                </div>
              </div>
            </div>
          </div>
          
    </>
  );
};

export default FulfillmentSlider;
