import React from "react";
import Footer from "src/Commons/Footer";
import Navbar from "src/Commons/Navbar";
import "src/Styles/Contact.css";
import { LuMapPin } from "react-icons/lu";
import { MdOutlinePhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaEarthEurope } from "react-icons/fa6";
const Contactus = () => {
  return (
    <>
      <Navbar />
      <div className="contact-banner">
        <img src="/serviceImages/contactus.png" alt="image" />
        <h1 className="">Contact-Us</h1>
      </div>
      <div className="container">
        <div className="row contact-content">
          <div className="col-md-6 col-12">
            <div className="branches-contact-left-side">
              <h1>Let's get in touch</h1>
              <p>We're open for any suggestion or just to have a chat</p>
              <div className="branches-contact-left-side-section">
                <LuMapPin />
                <div className="branches-contact-left-side-section-address">
                  <span>Address: </span>
                  <p> 6200 Rutland Ave, Riverside Ca 92503</p>
                </div>
              </div>
              <div className="branches-contact-left-side-section">
              <MdOutlinePhone />
                <div className="branches-contact-left-side-section-address">
                  <span>Phone:</span>
                  <p>833-666-3573</p>
                </div>
              </div>
              <div className="branches-contact-left-side-section">
              <MdEmail />
                <div className="branches-contact-left-side-section-address">
                  <span>Email:</span>
                  <p>info@bgntrasnport.com </p>
                </div>
              </div>
              <div className="branches-contact-left-side-section">
              <FaEarthEurope />
                <div className="branches-contact-left-side-section-address">
                  <span>Website: {" "}</span>
                  <a className="mx-2" href="https://branches.cyberx-infosystem.us/" >{" "} bgntrasnport.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="contact-content-section">
              <form>
                <div className="brnaches-model-from-section">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="mb-3 form-group branches-form-input-field">
                        <label className="form-label">First Name*</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="mb-3 form-group branches-form-input-field">
                        <label className="form-label">Last Name*</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-group branches-form-input-field">
                    <label className="form-label">Email address*</label>
                    <input type="email" className="form-control" />
                  </div>
                  <div className="mb-3 form-group branches-form-input-field">
                    <label className="form-label">Company*</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3 form-group branches-form-input-field">
                    <label className="form-label">Message</label>
                    <textarea type="text" className="form-control" />
                  </div>
                </div>
                <button
                  className="branchesglobalnetworks_global_button mt-3 w-100"
                  type="button"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactus;
