import React,{useEffect} from "react";
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import LastMileBanner from "src/LastMile/LastMileBanner";
import LastMileOptimazation from "src/LastMile/LastMileOptimazation";
import LastMileMiddleSec from "src/LastMile/LastMileMiddleSec";
import LastMileServiceCard from "src/LastMile/LastMileServiceCard";


const LastMile = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Navbar />
      <LastMileBanner />
      <LastMileOptimazation/>
      <LastMileServiceCard/>
      <LastMileMiddleSec/>
      <Footer />
    </>
  );
};

export default LastMile;
