import React, { useEffect } from "react";
import "src/Styles/DropShip/DropShipBanner.css";
import AOS from "aos";
import "aos/dist/aos.css";

const DropShipBanner = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return (
    <>
      <div className="DropShipBanner">
        <img src="/new_banners_images/stack_bed_banner.jpeg" alt="image" />
        <div className="">
          <div data-aos="zoom-in-up" className="DropShipBanner_content">
            <h1>Optimization of delivery service using Stake Beds</h1>
            <p>
              Stake bed trucks are ideal for delivery services due to their
              versatility in handling a variety of cargo, from oversized items
              to palletized goods. Their open design allows for easy loading and
              unloading, making them highly efficient for quick and flexible
              transportation needs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropShipBanner;
