import React,{useEffect} from "react";
import "src/Styles/middlesection.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function MiddleSection() {
  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <div>
      <div className="branches_middle_section_first_main py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Service Flexibility</h2>
                <p>
                  At Branches Global Networks, we recognize that every fleet is
                  unique, much like a fingerprint. Each fleet has its own
                  specific goals and challenges that necessitate personalized
                  solutions. Rather than offering generic recommendations, we
                  take the time to understand your organization in-depth – its
                  size, objectives, history, and risks. By doing so, we create a
                  tailored program that delivers precisely what you need,
                  ensuring the best possible outcomes and satisfaction.
                </p>
                <button className="branchesglobalnetworks_global_button mt-3">
                  Learn More
                </button>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/images/home_first_image.png"
                alt="section-first"
              />
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////// second section /////////// */}
      <div className="branches_middle_section_second_main py-5">
        <div className="container">
          <div className="row branches_middle_section_second_direction">
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/images/home_second_image.png"
                alt="section-first"
              />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Innovation</h2>
                <p>
                Fleet innovation is our expertise. As a genuine partner, not merely a vendor, we work closely with you to design the most agile and efficient fleet possible. We offer continuous support and consulting expertise to help you achieve your goals. Our creativity and responsiveness distinguish us, ensuring you experience the advantages of our innovative approach every day.
                </p>
                <button className="branchesglobalnetworks_global_button mt-3">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ///// third section//// */}

      <div className="branches_middle_section_third_main py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Network Of Delivery</h2>
                <p>
                Fleet innovation is our expertise. As a genuine partner, not merely a vendor, we work closely with you to design the most agile and efficient fleet possible. We offer continuous support and consulting expertise to help you achieve your goals. Our creativity and responsiveness distinguish us, ensuring you experience the advantages of our innovative approach every day.
                </p>
                <button className="branchesglobalnetworks_global_button mt-3">
                  Learn More
                </button>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/images/home_third_image.png"
                alt="section-first"
              />
            </div>
          </div>
        </div>
      </div>


       {/* ////////////////// forth section /////////// */}
       <div className="branches_middle_section_forth_main py-5">
        <div className="container">
          <div className="row branches_middle_section_forth_direction">
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/images/middle_section_image4.png"
                alt="section-first"
              />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Driver And Delivery Optimization</h2>
                <p>
                Fleet innovation is our expertise. As a genuine partner, not merely a vendor, we work closely with you to design the most agile and efficient fleet possible. We offer continuous support and consulting expertise to help you achieve your goals. Our creativity and responsiveness distinguish us, ensuring you experience the advantages of our innovative approach every day.
                </p>
                <button className="branchesglobalnetworks_global_button mt-3">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MiddleSection;
