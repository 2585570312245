import React from "react";
import "src/Styles/Values/ValueBanner.css";

const ValueBanner = () => {
  return (
    <>
      <div className="ValueBanner">
        <div data-aos="zoom-in-up" className="banner_content">
          <h1>Our Values </h1>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default ValueBanner;
