import React,{useEffect} from 'react'
import Footer from 'src/Commons/Footer'
import Navbar from 'src/Commons/Navbar'
import OurValue from 'src/HomeLayouts/OurValue'
import ValueBanner from 'src/Values/ValueBanner'
import AOS from "aos";
import "aos/dist/aos.css";

const Values = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return (
    <>
       <Navbar/>
       <ValueBanner/>
       <OurValue/>
       <Footer/>
    </>
  )
}

export default Values
