import React,{useEffect} from "react";
import Navbar from "src/Commons/Navbar";
import Footer from 'src/Commons/Footer'
import DefrencesBanner from "src/OurDefrences/DefrencesBanner";
import AOS from "aos";
import "aos/dist/aos.css";

const OurDefrences = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return (
    <>
      <Navbar />
      <DefrencesBanner/>
      <Footer />
    </>
  );
};

export default OurDefrences;
