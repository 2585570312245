
import "./App.css";
import Routes from "src/Routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
