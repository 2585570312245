import React,{useEffect} from "react";
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import WhiteLayoutBanner from "src/WhiteLayout/WhiteLayoutBanner";
import WhitegloveOptimazation from "src/WhiteLayout/WhitegloveOptimazation";
import WhiteMiddleSec from "src/WhiteLayout/WhiteMiddleSec";
import LastMileMiddleSec from "src/LastMile/LastMileMiddleSec";


const WhiteLayout = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Navbar />
      <WhiteLayoutBanner/>
      <WhitegloveOptimazation/>
      <WhiteMiddleSec/>
      <LastMileMiddleSec/>
      <Footer />
    </>
  );
};

export default WhiteLayout;
