import React,{useEffect} from "react";
import "src/Styles/LastMile/LastMileServiceCard.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


const FullfilmentServiceCard = () => {

  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <>
      <div className="LastMileServiceCard">
        <div className="container">
          <div className="branches-middle-main_first_section">
            <div className="row d-flex align-items-center my-5">
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/fulfillment_slider_img1.png"
                    alt="middleimage"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Logistic service at its best</h1>
                    <p>
                    Your consignment is delivered straight from our logistics center to the recipient with no intermediate stops. Our direct delivery method ensures swift handling of your order, saving you time and reducing costs
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center my-5">
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Logistic service at its best</h1>
                    <p>
                    Our best-in-class trucking service handles both small and large loads with care. Experienced drivers ensure your products are checked and transported efficiently. With frequent departures and direct transport connections, we guarantee swift and reliable distribution
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/fulfillment_slider_img2.png"
                    alt="middleimage"
                  />
                </div>
              </div>
            </div>
    
          </div>

          <div className="branches-service-bottom-main">
            <div className="container">
              <div className="branches-service-bottom-section">
                <img src="/stack_images/stake_bottom_image_2.png" alt="bottom" />
                <div className="branches-about-bottom-content">
                  <h3> Join Us In Building The Future Of Mobility</h3>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default FullfilmentServiceCard;
