import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMailOpen } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import "src/Styles/footer.css";

function Footer() {
  const navigation = useNavigate();
  return (
    <div className="footer">
      <div className="container">
        <div className="row border-bottom pb-4">
          <div className="col-lg-4">
            <div className="footer-first">
              <img src="/images/logo_white_with_color.png" alt="Image" />
              <p>
                Branches Global Networks, a stalwart in the industry, embodies a legacy of 15 years rooted in stability, resilience, and ethical conduct.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-center">
                    <h3>Quick Link</h3>
                    <ul>
                      <li onClick={() => {
                        navigation("/our-difference")
                        window.scrollTo(0, 0)
                      }}>
                        Our Difference
                      </li>
                      <li onClick={() => {
                        navigation("/values")
                        window.scrollTo(0, 0)
                      }}>Our Values</li>
                      <li onClick={() => {
                        navigation("/about-us")
                        window.scrollTo(0, 0)
                      }}>About Us</li>
                      <li onClick={() => {
                        navigation("/contact-us")
                        window.scrollTo(0, 0)

                      }}>Contact Us</li>
                      <li onClick={() => {
                        navigation("/terms")
                        window.scrollTo(0, 0)

                      }}>Terms & Conditions </li>



                    </ul>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-center">
                    <h3>Contact Us</h3>
                    <ul>
                      <li onClick={() => navigation("/startup-login")}>
                        <span>
                          <FaLocationDot />
                        </span>
                        <p className="m-0 pl-2">
                          6200 Rutland Ave, Riverside Ca 92503
                        </p>
                      </li>
                      <li>
                        <span>
                          <IoMdMailOpen />
                        </span>
                        <p className="m-0 p-0">info@bgntrasnport.com </p>
                      </li>
                      <li>
                        <span>
                          <FaPhone />
                        </span>
                        <p> 833-666-3573</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-center">
              <h2>Opening Hours</h2>
              <p className="mt-2" >Weekdays: 9:00 am – 6:00pm</p>
              <p className="mt-2" >Weekend: Close</p>
              <h2 className=" mt-3 d-none" >Get Connected</h2>
              <div className="branches_footer_social_media-icon d-none">
                <a href="https://norbaq.com" target="blank">
                  {" "}
                  <AiFillLinkedin />
                </a>

                <a href="https://norbaq.com" target="blank">
                  <AiFillInstagram />
                </a>
                <BsTwitter />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 d-flex align-items-center">
            <div className="footer-bottom-first">
              <p onClick={() => {
                navigation("/privacy")
                window.scrollTo(0, 0)

              }}>Privacy Policy</p>
              <p onClick={() => {
                navigation("/terms")
                window.scrollTo(0, 0)

              }}>
                Terms & Conditions
              </p>
            </div>
          </div>
          <div className="col-md-9 d-flex align-items-center justify-content-end">
            <p className="copyright">
              © 2024 Copyright - BRANCHES GLOBAL NETWORK. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
