import React from 'react'
import "src/Styles/Service/servicebottom.css";

const LastMileMiddleSec = () => {
  return (
    <>
    <div className='container'>
       <div class="branches-service-bottom-main">
            <div class="">
              <div class="branches-service-bottom-section">
                <img
                  src="/serviceImages/servicebottomimage2.jpg"
                  alt="bottom"
                />
                <div class="branches-service-bottom-content">
                  <h4>Get Service Within 24 Hours</h4>
                  <p>
                    White glove service offers unparalleled convenience by
                    requiring only a 24-hour notice for service. This quick
                    response time ensures that customers receive their
                    deliveries promptly and efficiently, with each item handled
                    with the utmost care. By providing such responsive service,
                    we enhance customer satisfaction and reliability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
    </>
  )
}

export default LastMileMiddleSec
