import React,{useEffect} from 'react'
import "src/Styles/Ecommers/EcommersBanner.css";
import AOS from "aos";
import "aos/dist/aos.css";

const EcommersBanner = () => {

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return ( 
    <>
       <div className="EcommersBanner">
        <img src="/new_banners_images/ecommerce_banner.png" alt="image" />
        <div className="">
          <div data-aos="zoom-in-up" className="EcommersBanner_content">
            <h1>Tours
            </h1>
            <p>At Branches Global Networks, we maintain our vehicles rigorously to ensure every trip is safe and reliable. Our comprehensive maintenance program guarantees that all our trucks are in top condition, providing peace of mind for each delivery.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default EcommersBanner
