import React, { useEffect } from "react";
import "src/Styles/home_banner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


function Banner() {

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    })
  }, [])

  return (
    <>
      <div className="branches_home_banner_main">
        <div className="branches_home_banner_image">
          <video width="100%" height="auto" playsInline autoPlay muted loop>
            <source src="/images/home_banner_video_new.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="branches_home_banner_image_mobile">
          <video width="100%" height="auto" playsInline autoPlay muted loop>
            <source src="/images/home_banner_video_new.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="container-fluid">
          <div data-aos="fade-right" className="branches_home_banner_content ">
            <h1>Flexibility and Integration</h1>
            <h6 className="mt-4">
            At Branches Global Networks, we pride ourselves on being a trusted partner in transportation, delivering seamless and reliable logistics solutions that keep your business moving forward.
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
