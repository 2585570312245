import React, { useEffect } from "react";
import Footer from "src/Commons/Footer";
import Navbar from "src/Commons/Navbar";
import "src/Styles/Aboutus.css";
import { FaLinkedin } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="About-banner">
        <h1 data-aos="zoom-in-up" className="">
          Accelerating the path to sustainable mobility
        </h1>
      </div>
      <div className="container">
        <div className="about_content">
          <div className="row d-flex align-items-center">
            <div className=" col-md-6">
              <div data-aos="zoom-in-up" className="about_left_sec">
                <a href="/about-us">ABOUT US</a>
                <h2>
                  The Leading Optimization Platform <span>for Fleet</span>{" "}
                </h2>
                <p>
                  Branches Global Networks, a stalwart in the industry, embodies
                  a legacy of 15 years rooted in stability, resilience, and
                  ethical conduct. Our commitment to excellence is exemplified
                  through our comprehensive suite of solutions, tailored to meet
                  the diverse needs of our clientele.
                </p>
                <p>
                  Serving as your trusted partner, we offer a white glove
                  approach, ensuring the utmost attention to detail and
                  personalized service. From last-mile logistics to nationwide
                  coverage, our expertise extends across retail, casino
                  industry, food service, industrial, and fast-moving consumer
                  goods sectors With a dedicated focus on delivering
                  unparalleled value and efficiency, we collaborate closely with
                  our clients to provide bespoke solutions precisely tailored to
                  their requirements, thereby setting new standards of
                  excellence in the industry
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div data-aos="zoom-in-up" className="image">
                <img src="/images/home_first_image.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about-content-section">

          <ul>
            <li>
              Branches Global Networks, a stalwart in the industry, embodies a
              legacy of 15 years rooted in stability, resilience, and ethical
              conduct. Our commitment to excellence is exemplified through our
              comprehensive suite of solutions, tailored to meet the diverse
              needs of our clientele.
            </li>


          <li>
            Serving as your trusted partner, we offer a white glove approach,
            ensuring the utmost attention to detail and personalized service.
            From last-mile logistics to nationwide coverage, our expertise
            extends across retail, casino industry, food service, industrial,
            and fast-moving consumer goods sectors.
          </li>


          <li>
            With a dedicated focus on delivering unparalleled value and
            efficiency, we collaborate closely with our clients to provide
            bespoke solutions precisely tailored to their requirements, thereby
            setting new standards of excellence in the industry.
          </li>
          </ul>

        </div> */}
        {/* <div className="about-Our-section">
          <div className="about-content">
            <h1>Our Founders</h1>
          </div>
          <div className="row">
            <div className="col-md-4 col-12 mt-md-0 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus2.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Dor Shay</h6>
                    <FaLinkedin />
                  </div>
                  <p>CTO</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus3.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Kobi Eisenberg</h6>
                    <FaLinkedin />
                  </div>
                  <p>CTO</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus4.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Avi Hauben</h6>
                    <FaLinkedin />
                  </div>
                  <p>Advisour</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="about-Our-section">
          <div className="about-content">
            <h1>Board & Advisour</h1>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12 mt-md-0 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus7.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Dor Shay</h6>
                  
                  </div>
                  <p>Board Director</p>
                  <div className="about-advisor-section">
                    <p>Founder,Maniv mobility</p>
                    <FaLinkedin />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-md-0 mt-3 ">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus8.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Kobi Eisenberg</h6>
                  
                  </div>
                  <p>Board Director</p>
                  <div className="about-advisor-section">
                    <p>Partner, MizMaa Ventures</p>
                    <FaLinkedin />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-md-0 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus9.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Avi Hauben</h6>
                  
                  </div>
                  <p>Board Director</p>
                  <div className="about-advisor-section">
                    <p>Partner, Keyframe Capital</p>
                    <FaLinkedin />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-lg-0 mt-md-2 mt-3">
              <div className="branches-about-card">
                <img src="AboutImages/Aboutus10.jpg" alt="about" />
                <div className="branches-about-card-content">
                  <div className="brances-about-card-content-heading">
                    <h6>Avi Hauben</h6>
                 
                  </div>
                  <p>Advisour</p>
                  <div className="about-advisor-section">
                    <p>Former CIO, Avis Budget</p>

                    <FaLinkedin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          {/* <div className="branches-about-investor">
            <div className="branches-about-investor-heading">
              <h6>Investor</h6>
            </div>
            <div className="branches-about-design">
              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/investor1.svg" alt="inverstor" />
              </div>

              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/inverstor4.svg" alt="inverstor" />
              </div>

              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/inverstor11.svg" alt="inverstor" />
              </div>
            </div>
            <div className="branches-about-design">
              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/inverstor12.svg" alt="inverstor" />
              </div>

              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/inverstor5.svg" alt="inverstor" />
              </div>

              <div className="branches-about-inverstor-section">
                <img src="/AboutImages/inverstor5.svg" alt="inverstor" />
              </div>
            </div>
          </div> */}
          <div className="branches-service-bottom-main">
            <div className="container">
              <div className="branches-service-bottom-section">
                <img src="/serviceImages/servicebottomimage.png" alt="bottom" />
                <div className="branches-about-bottom-content">
                  <h3> Join Us In Building The Future Of Mobility</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
