import React,{useEffect} from "react";
import "src/Styles/Service/Middleservice.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


const WhiteMiddleSec = () => {

useEffect(() => {
  AOS.init({
       duration: 800,
       once: false,
     })
}, [])

  return (
    <>
      <div className="branches-middle-main">
        <div className="container">
          <div className="branches-middle-main_first_section">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/whitemiddleservice1.png"
                    alt="middleimage"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Workload Balancing</h1>
                    <p>
                      Balancing the transportation workload is key to ensuring
                      on-time delivery. By evenly distributing tasks among
                      vehicles and drivers and using data analytics for informed
                      decisions, you can avoid bottlenecks and delays. This
                      approach enhances efficiency and reliability, ensuring
                      deliveries are consistently on schedule and meeting
                      customer expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="branches-middle-section-2 row d-flex flex-wrap-reverse">
            <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
              <div className="branches-middle-service-right">
                <div className="branches-miidle-service-right-image"></div>
                <div data-aos="zoom-in-up" className="branches-middle-service-right-section">
                  <h1>The Power Of Planning And Re-Optimization</h1>
                  <p>
                    Optimizing routes for white glove service ensures quicker,
                    more efficient deliveries. By streamlining paths, we reduce
                    wait times and enhance reliability, guaranteeing each
                    product is handled with the utmost care, thus improving
                    customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
              <div data-aos="zoom-in-up" className="branches-middle-image text-center">
                <img src="/serviceImages/whiteglovedriverroute.gif" alt="middleimage" />
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default WhiteMiddleSec;
