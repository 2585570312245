import React,{useEffect} from 'react'
import "src/Styles/Whitelayout/whitelayoutOptimazation.css";

const WhitegloveOptimazation = () => {
  
  return (
    <>
      <div className="whitegloveOptimazation">
        <div className="container">
          <div className="service-optimazation-heading">
            <h2>Optimize Your Fleet Management</h2>
          </div>
          <div className="service-optimazation-section">
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/graph_image.png"
                  alt="logo"
                />
                <h6>Superior White Glove</h6>
                <p>
                  White glove service ensures swift delivery and meticulous
                  handling of products. By reducing wait times and treating each
                  item with exceptional care, this premium service guarantees
                  customer satisfaction and trust, ensuring products arrive in
                  pristine condition.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/meter_image.png "
                  alt="logo"
                />
                <h6>Improving customer Experience</h6>
                <p>
                  White glove service enhances the customer experience by
                  offering swift delivery and meticulous care. By reducing wait
                  times and ensuring each product is handled with precision,
                  this premium service boosts customer satisfaction and trust.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/clock_image.png"
                  alt="logo"
                />
                <h6>98% Customer Satisfaction</h6>
                <p>
                  Our company has a customer satisfaction rate of 98%,
                  highlighting our exceptional performance and outstanding
                  service. This rating reflects our dedication to consistently
                  exceeding customer expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhitegloveOptimazation
