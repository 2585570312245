import React from "react";
import TopBar from "src/Commons/TopBar";
import Navbar from "src/Commons/Navbar";
import Banner from "src/HomeLayouts/Banner";
import Services from "src/HomeLayouts/Services";
import MiddleSection from "src/HomeLayouts/MiddleSection";
import OurValue from "src/HomeLayouts/OurValue";
import Blog from "src/HomeLayouts/Blog";
import QuotesSection from "src/HomeLayouts/QuotesSection";
import Footer from "src/Commons/Footer";


function Home() {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar />
      <Banner />
      <Services />
      <MiddleSection />
      {/* <OurValue /> */}
      <Blog />
      <div className="position-relative">
        <QuotesSection />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
