import React,{useEffect} from "react";
import "src/Styles/LastMile/LastMileServiceCard.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


const LastMileServiceCard = () => {

  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <>
      <div className="LastMileServiceCard">
        <div className="container">
          <div className="branches-middle-main_first_section">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/fullmiddleservice7.png"
                    alt="middleimage"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Workload Balancing</h1>
                    <p>
                      Balancing the transportation workload is key to ensuring
                      on-time delivery. By evenly distributing tasks among
                      vehicles and drivers and using data analytics for informed
                      decisions, you can avoid bottlenecks and delays. This
                      approach enhances efficiency and reliability, ensuring
                      deliveries are consistently on schedule and meeting
                      customer expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Inner City deliveries</h1>
                    <p>
                      Inner city delivery demands high performance to navigate
                      urban complexities. Efficient route planning, real-time
                      traffic monitoring, and agile vehicles are essential for
                      timely deliveries. Skilled drivers and technology for
                      tracking and communication ensure seamless coordination,
                      maintaining high service levels and customer satisfaction
                      in a fast-paced city environment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/fullmiddleservice6.png"
                    alt="middleimage"
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
                <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                  <img
                    src="/serviceImages/fullmiddleservice3.png"
                    alt="middleimage"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
                <div className="branches-middle-service-right">
                  <div className="branches-miidle-service-right-image"></div>
                  <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                    <h1>Home deliveries</h1>
                    <p>
                      Home deliveries that are on time and handled with care are
                      crucial for customer satisfaction. Timely deliveries
                      require efficient logistics and precise scheduling to
                      ensure packages arrive as expected. Handling items with
                      care involves trained personnel and proper packaging to
                      prevent damage. Combining punctuality with careful
                      handling not only meets customer expectations but also
                      builds trust and loyalty, enhancing the overall delivery
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="branches-middle-section-2 row d-flex flex-wrap-reverse">
            <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-center">
              <div className="branches-middle-service-right">
                <div className="branches-miidle-service-right-image"></div>
                <div  data-aos="zoom-in-up" className="branches-middle-service-right-section">
                  <h1>The Power Of Planning And Re-Optimization</h1>
                  <p>
                    Optimizing routes for white glove service ensures quicker,
                    more efficient deliveries. By streamlining paths, we reduce
                    wait times and enhance reliability, guaranteeing each
                    product is handled with the utmost care, thus improving
                    customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center">
              <div  data-aos="zoom-in-up" className="branches-middle-image text-center">
                <img src="/serviceImages/whiteglovedriverroute.gif" alt="middleimage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastMileServiceCard;
