import React from 'react'
import './../Styles/blogdetails.css'
import Navbar from "src/Commons/Navbar";
import Footer from 'src/Commons/Footer';

function BlogDetails() {
    let pathName = window.location.pathname.split("/")[2]

    let blogsDataList = [
        {
            title: "The Reliable Backbone of Transportation: Branches Global Networks",

            des: "In the fast-paced world of transportation and logistics, reliability isn’t just a quality—it's a necessity. At Branches Global Networks, we understand that every shipment, every delivery, and every connection we facilitate is a critical piece of our clients' operations. Our commitment to reliability is the cornerstone of our service, ensuring that your goods are where they need to be, exactly when they need to be there.",

            heading1: "A Commitment to Excellence",

            des1: "Reliability starts with a commitment to excellence. At Branches Global Networks, we don’t just meet industry standards—we exceed them. Our network is built on a foundation of meticulous planning, state-of-the-art technology, and a team of professionals who are experts in their fields. Whether it’s land, air, or sea, our services are designed to handle the complexities of global logistics with precision and care.",

            heading2: "Technology-Driven Reliability",

            des2: "In today’s digital age, the backbone of reliable service is robust technology. We invest heavily in the latest logistics technologies to provide real-time tracking, predictive analytics, and seamless communication. Our clients have access to up-to-the-minute updates on their shipments, allowing them to make informed decisions and stay ahead of the curve. This transparency not only fosters trust but also ensures that any potential issues are addressed proactively, minimizing disruptions.",

            heading3: "A Network You Can Trust",

            des3: "Branches Global Networks isn’t just a name—it’s a promise. Our extensive network spans across continents, connecting major hubs and remote destinations with the same level of efficiency and reliability. We have forged strong partnerships with local carriers, customs agencies, and port authorities, ensuring that every link in the supply chain is as strong as the last. This global reach, combined with our local expertise, means that we can navigate the complexities of international logistics with ease, giving our clients peace of mind.",

            heading4: "People at the Heart of Reliability",

            des4: "While technology and infrastructure are crucial, it’s the people behind the scenes who truly make the difference. Our team at Branches Global Networks is dedicated to ensuring that every shipment is handled with care and precision. From our drivers and warehouse staff to our customer service representatives, everyone is aligned with our mission of providing reliable service. We understand that logistics is a people business, and we pride ourselves on the relationships we build with our clients, treating their needs as our own.",

            heading5: "A Track Record of Success",

            des5: "Our reputation for reliability isn’t just something we claim—it’s something we’ve earned through years of consistent service. Our clients range from small businesses to multinational corporations, and they all trust us to deliver on our promises. We’ve successfully managed complex supply chains, time-sensitive deliveries, and everything in between. Our track record speaks for itself, and we’re proud to be the transportation partner that businesses rely on.",

            heading6: "Looking Ahead",

            des6: "As we move into the future, Branches Global Networks remains committed to enhancing our reliability even further. We are continuously exploring new technologies, expanding our network, and refining our processes to ensure that we stay ahead of the curve. In an industry where the only constant is change, our unwavering focus on reliability ensures that we are not just adapting but leading the way.",
            heading7:'',
            des7: "In conclusion, when you choose Branches Global Networks, you’re not just choosing a transportation company—you’re choosing a partner dedicated to your success. Our reliability is your competitive edge, and we’re here to support your business every step of the way.",
            heading8:"",
            des8: "Experience the reliability of Branches Global Networks today, and see why we’re the trusted choice for businesses around the world.",
            heading9:"",
            des9:""
        },
        {
            title: "Building Strong Partnerships: How Branches Global Networks Understands and Meets Customer Needs",

            des: "In the dynamic world of transportation and logistics, choosing the right partner can make all the difference. At Branches Global Networks, we pride ourselves on being more than just a service provider; we are a partner dedicated to understanding and fulfilling the unique needs of each of our customers. Our approach is centered on collaboration, communication, and a deep commitment to ensuring that our clients’ businesses thrive.",

            heading1: "A Partnership Built on Understanding",

            des1: "Every business is unique, with its own set of challenges, goals, and requirements. At Branches Global Networks, we take the time to truly understand our clients’ businesses. We don’t offer one-size-fits-all solutions; instead, we tailor our services to meet the specific needs of each customer. Whether you’re a small business looking to streamline your supply chain or a large corporation managing complex global logistics, we work closely with you to develop strategies that align with your goals.",

            heading2: "Listening to Our Customers",

            des2: "One of the key factors that sets us apart is our commitment to listening. We believe that the foundation of any successful partnership is effective communication. From the moment you engage with us, our team is dedicated to understanding your needs and concerns. We listen to your challenges, your goals, and your feedback, ensuring that our services evolve to meet your changing needs. This collaborative approach allows us to provide solutions that are not only effective but also aligned with your business objectives.",

            heading3: "Customized Solutions for Every Need",

            des3: "Understanding our customers means recognizing that each one has different needs. That’s why we offer customized solutions designed to address specific challenges. Our team of experts works with you to identify the most efficient and cost-effective ways to move your goods. Whether you need expedited shipping, specialized handling, or support navigating complex regulatory environments, Branches Global Networks has the expertise to deliver solutions that work for you.",

            heading4: "A Partner You Can Trust",

            des4: "Trust is the cornerstone of any successful partnership, and at Branches Global Networks, we’ve built our reputation on it. Our clients trust us because we consistently deliver on our promises. We understand that your business depends on reliable, timely transportation, and we take that responsibility seriously. Our team is committed to ensuring that your goods arrive safely, on time, and in perfect condition—every time.",

            heading5: " Proactive Problem-Solving",

            des5: "In the world of logistics, challenges are inevitable. What sets Branches Global Networks apart is our proactive approach to problem-solving. We don’t wait for issues to arise; we anticipate them and take steps to prevent them before they can impact your business. Our team is always on the lookout for potential disruptions, whether it’s a delay at a port, a change in regulations, or a weather event. By staying ahead of the curve, we ensure that your operations run smoothly, no matter what challenges arise.",

            heading6: " Long-Term Partnerships for Long-Term Success",

            des6: "At Branches Global Networks, we don’t just focus on short-term gains; we’re invested in your long-term success. We view every customer relationship as a long-term partnership, and we’re committed to supporting your business as it grows and evolves. Our goal is to be a partner you can rely on, not just today but for years to come. We measure our success by the success of our clients, and we’re proud to be a trusted partner to businesses around the world.",

            heading7: " Going the Extra Mile",

            des7: "What truly sets Branches Global Networks apart is our willingness to go the extra mile for our customers. We understand that sometimes, what you need is more than just a transportation service—you need a partner who’s willing to do whatever it takes to get the job done. Whether it’s working late to ensure a shipment is on time, providing real-time updates so you’re never in the dark, or finding innovative solutions to complex challenges, we’re here to support you every step of the way.",
            heading8:"",
            des8: "In conclusion, Branches Global Networks isn’t just a transportation company; we’re a partner who understands your needs, listens to your concerns, and delivers customized solutions that help your business thrive. We’re here to support your success, and we’re committed to building a partnership that grows with you.",
            heading9:"",
            des9: "Experience the difference a true partnership can make—choose Branches Global Networks and let us help your business reach new heights."
        },
        {
            title: "Why Branches Global Networks Is a Great Place to Work",

            des: "In today’s competitive job market, finding a workplace that not only values its employees but also invests in their growth and well-being is a rare and valuable discovery. At Branches Global Networks, we pride ourselves on being more than just a transportation company; we are a community where our employees are empowered, supported, and inspired to achieve their best. Here’s why Branches Global Networks is not just a great place to work, but the right place to grow your career.",

            heading1: "A Culture of Respect and Inclusion",

            des1: "At the heart of Branches Global Networks is a culture built on respect and inclusion. We believe that every employee, regardless of their role, contributes to the success of our company. Our diverse workforce is one of our greatest strengths, and we are committed to fostering an environment where everyone feels valued and respected. We celebrate differences and ensure that all voices are heard, creating a workplace where innovation thrives and collaboration is seamless.",

            heading2: "Opportunities for Growth and Development",

            des2: "When you join Branches Global Networks, you’re not just taking a job—you’re embarking on a career journey. We are dedicated to the professional growth of our employees and provide numerous opportunities for learning and development. From in-house training programs to support for external certifications, we encourage our team members to continuously enhance their skills. Whether you’re looking to advance in your current role or explore new career paths within the company, we’re here to support your ambitions.",

            heading3: "Work-Life Balance Matters",

            des3: "We understand that a fulfilling career is one that supports a healthy work-life balance. At Branches Global Networks, we offer flexible work arrangements, generous paid time off, and a range of wellness programs designed to help our employees maintain balance in their lives. We believe that when our team members are happy and healthy, they’re more productive and engaged at work. That’s why we’re committed to creating an environment that supports both professional success and personal well-being.",

            heading4: "A Focus on Teamwork and Collaboration",

            des4: "In the fast-paced world of logistics, teamwork is essential. At Branches Global Networks, we foster a collaborative environment where employees work together to solve challenges and achieve common goals. Our team-oriented approach ensures that everyone has a role to play in our success, and we encourage open communication and the sharing of ideas. We believe that when we work together, we can achieve more, and our employees are always ready to lend a hand to help each other succeed.",

            heading5: "Recognition and Reward",

            des5: "At Branches Global Networks, we believe in recognizing and rewarding hard work and dedication. We regularly celebrate the achievements of our employees through formal recognition programs, performance bonuses, and career advancement opportunities. Our leadership team is committed to acknowledging the contributions of our team members, and we ensure that our employees feel appreciated and valued for the work they do. ",

            heading6: "A Safe and Supportive Work Environment",

            des6: "The safety and well-being of our employees is our top priority. We are committed to maintaining a safe work environment, with rigorous safety protocols and ongoing training to ensure that all employees are equipped to work safely and confidently. Additionally, we offer a range of support services, including mental health resources and employee assistance programs, to ensure that our team members have the support they need both at work and in their personal lives.",

            heading7: "Making a Difference Together",

            des7: "At Branches Global Networks, we’re not just moving goods from one place to another—we’re making a difference in the world. Our work impacts businesses, communities, and economies, and our employees are proud to be part of something bigger. We encourage our team members to get involved in our corporate social responsibility initiatives, from volunteering in local communities to participating in sustainability efforts. Together, we’re not only building a successful company, but we’re also making a positive impact on the world around us.",

            heading8: "Join Us at Branches Global Networks",

            des8: "If you’re looking for a workplace where you can grow, collaborate, and make a difference, Branches Global Networks is the place for you. We’re more than just a transportation company—we’re a team of dedicated professionals who are passionate about what we do. We’re committed to providing a supportive, inclusive, and rewarding environment where you can achieve your best and build a fulfilling career.",
            heading9:"",
            des9: "Explore career opportunities at Branches Global Networks and discover why we’re a great place to work. Your journey starts here."
        },
    ]
    return (
        <div>
            <Navbar />
            <div className='blog_details_main' >
                <div className="blog_details_banner">
                    <img src="/images/our_value_banner.png" alt="image" />
                    <div className='blog_details_banner_text'>
                        <h1>Blog</h1>
                        <h4>{blogsDataList[pathName]?.title}</h4>
                    </div>
                </div>

                <div className='blog_details_des' >
                    <h2>{blogsDataList[pathName]?.title}</h2>
                    <p>{blogsDataList[pathName]?.des}</p>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading2}</h4>
                    <h6>{blogsDataList[pathName]?.des2}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading3}</h4>
                    <h6>{blogsDataList[pathName]?.des3}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading4}</h4>
                    <h6>{blogsDataList[pathName]?.des4}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading5}</h4>
                    <h6>{blogsDataList[pathName]?.des5}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading6}</h4>
                    <h6>{blogsDataList[pathName]?.des6}</h6>

                  
                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading7}</h4>
                    <h6>{blogsDataList[pathName]?.des7}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading8}</h4>
                    <h6>{blogsDataList[pathName]?.des8}</h6>

                    <h4 className='mt-3' >{blogsDataList[pathName]?.heading9}</h4>
                    <h6>{blogsDataList[pathName]?.des9}</h6>


                </div>

            </div>
            <Footer />
        </div>
    )
}

export default BlogDetails
