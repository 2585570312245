import React from 'react'
import "src/Styles/LastMile/LastMileOptimazation.css";

const LastMileOptimazation = () => {
  return (
    <>
       <div className="lastMileOptimazation">
        <div className="container">
          <div className="service-optimazation-heading">
            <h2>Optimize Your Fleet Management</h2>
          </div>
          <div className="service-optimazation-section">
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/meter_image.png"
                  alt="logo"
                />
                <h6>Reduce Fleet Downtime</h6>
                <p>
                Reducing fleet downtime is key to operational efficiency and cost savings. Regular, proactive truck maintenance prevents major issues by addressing problems early, minimizing unexpected breakdowns. This keeps vehicles in optimal condition, extends their lifespan, and ensures the fleet runs smoothly and reliably.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/clock_image.png "
                  alt="logo"
                />
                <h6>Improve customer experience</h6>
                <p>
                Leverage data to boost customer satisfaction by improving availability, performance, and responsiveness. Use advanced analytics to predict and meet customer needs, ensuring services are always accessible and optimized. Promptly address customer inquiries and feedback to create a reliable and satisfying experience, building trust and loyalty.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="service-optimazation-content">
                <img
                  src="/serviceImages/scooter_image.png"
                  alt="logo"
                />
                <h6>Increase Utilization</h6>
                <p>
                Keep vehicles moving and ensure fast delivery with predictive demand, automation, and operational efficiency. Use data analytics to forecast demand and allocate resources effectively. Streamline processes through automation to enhance productivity and reduce errors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LastMileOptimazation
