import React, { useState } from "react";
import "src/Styles/Carreier/Careerjob.css";
import Code from "src/Code";
const CareerJob = () => {
  const [selectCountry, setSelectCountry] = useState("India");
  return (
    <div className="branches-career-job-main">
      <div className="container">
        <div className="branches-career-job-heading">
          <h1>Job Openings</h1>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-4">
          <div className="barnches-career-job-location-section">
            <div className="barnches-career-job-location-selected">
              Select Location
            </div>

            <select

              id="location-selected"
              style={{ width: "100%", height: "auto", padding: "10px" }}
              value={selectCountry}
              onChange={(e) => setSelectCountry(e.target.value)}
            >
              <option selected>{selectCountry}</option>
              {Code?.map((ele, i) => {
                return (
                  <option key={i} value={ele.name}>
                    {ele.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="barnches-career-job-talent-section">
          <div className="barnches-career-job-talent-section-left">
            <p>Can't find the perfect position ?</p>
            <h2>Talent Is Always On Our Radar!</h2>
          </div>
          <div className="barnches-career-job-talent-section-right d-lg-flex d-none">
            <button
              className="branchesglobalnetworks_global_button mt-3 w-100"
              type="button"
            >
              Reach out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerJob;
