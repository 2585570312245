import React from "react";
import Footer from "src/Commons/Footer";
import Navbar from "src/Commons/Navbar";
import "src/Styles/Privacy.css";
const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="branches-privacy-section-main">
        <div className="privacy-banner">
          <h1 className="">Privacy Policy</h1>
        </div>
        <div className="container mt-4">
          <div className="branches-terms-heading">
            <h4>
              Branches Global Network Transportation Services: Terms and
              Conditions
            </h4>
            <p className="m-0"> Global Branches Network, LLC</p>
            <p>Effective Date: Tuesday, July 16, 2024</p>
            <h6 className="fw-bold">
              Global Branches Network, LLC ("we," "our," or "us") values your
              privacy. This Privacy Policy outlines how we collect, use,
              disclose, and protect your personal information when you use our
              transportation services. By accessing and using our services, you
              agree to the terms of this Privacy Policy.
            </h6>
          </div>
          <div className="branches-terms-content-section">
            <div className="branches-terms-content-section-heading">
              <h6>1. Information We Collect</h6>
              <p>We may collect the following types of information:</p>
              <ul>
                <li>
                  Personal Information: This includes your name, address, phone
                  number, email address, payment information, and any other
                  information you provide when booking our services.
                </li>
                <li>
                  Service Information: Details about the services you use,
                  including booking dates, pickup and delivery addresses, and
                  the nature of the goods being transported.
                </li>
                <li>
                  Technical Information: Information about your device and
                  internet connection, including IP address, browser type, and
                  operating system.
                </li>
                <li>
                  Usage Information: Information about how you use our website
                  and services, including pages viewed and the duration of your
                  visit.
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>2. How We Use Your Information</h6>
              <p>We use your information for the following purposes</p>
              <ul>
                <li>
                  Service Provision: To provide, manage, and improve our
                  transportation services, including booking, pickup, delivery,
                  and customer support.
                </li>
                <li>
                  Payment Processing: To process payments for our services.
                </li>
                <li>
                  Communication: To communicate with you about your bookings,
                  respond to inquiries, and provide updates about our services.
                </li>
                <li>
                  Marketing: To send you promotional materials and updates about
                  our services, with your consent where required by law.
                </li>
                <li>
                  Legal Compliance: To comply with applicable laws and
                  regulations, and to protect our legal rights and interests.
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>3. Information Sharing and Disclosure</h6>
              <p>
                We do not sell or rent your personal information to third
                parties. We may share your information in the following
                circumstances
              </p>
              <ul>
                <li>
                  Service Providers: We may share your information with
                  third-party service providers who assist us in providing our
                  services, such as payment processors, IT support, and
                  logistics partners. These providers are contractually
                  obligated to protect your information and use it only for the
                  purposes for which it was disclosed.
                </li>
                <li>
                  Legal Requirements: We may disclose your information if
                  required to do so by law, or if we believe such action is
                  necessary to comply with legal obligations, protect our
                  rights, or ensure the safety of our customers and employees.
                </li>
                <li>
                  Business Transfers: In the event of a merger, acquisition, or
                  sale of all or a portion of our assets, your information may
                  be transferred to the new owner.
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>4. Data Security</h6>
              <p>
              We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>5. Your Rights</h6>
              <p>
              You have the following rights regarding your personal information
              </p>
              <ul>
                <li>Access and Correction: You have the right to access and correct your personal information. You can update your information by contacting us at insert contact information</li>
                <li>Deletion: You have the right to request the deletion of your personal information, subject to certain legal exceptions</li>
                <li>Objection and Restriction: You have the right to object to or request the restriction of the processing of your personal information in certain circumstances</li>
                <li>Data Portability: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format</li>
              </ul>
              <p>To exercise these rights, please contact us at [insert contact information].</p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>6. Cookies and Tracking Technologies</h6>
              <p>
              We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small text files stored on your device that help us understand how you use our website and improve our services. You can manage your cookie preferences through your browser settings.
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>7. Children's Privacy</h6>
              <p>
              Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child, we will take steps to delete such information.
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>8. Changes to This Privacy Policy</h6>
              <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on our website and will become effective upon posting. Your continued use of our services after the changes take effect constitutes your acceptance of the revised Privacy Policy.
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>Contact Us</h6>
              <p>
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at
              </p>
              <div className="gap-5">
                <p><span className="fw-bold">Phone:</span>  833-666-3573</p>
                <p> <span className="fw-bold">Email: </span> info@bgntrasnport.com </p>
                <p><span className="fw-bold">Address:</span> 6200 Rutland Ave, Riverside Ca 92503</p>
              </div>
               <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
