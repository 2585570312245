import React from "react";
import Footer from "src/Commons/Footer";
import Navbar from "src/Commons/Navbar";
import "src/Styles/Privacy.css";

const Terms = () => {
  return (
    <>
      <Navbar />
      <div className="branches-privacy-section-main">
        <div className="privacy-banner">
          <h1 className="">Terms and Conditions</h1>
        </div>
        <div className="container mt-4">
          <div className="branches-terms-heading">
            <h4>
              Branches Global Network Transportation Services: Terms and
              Conditions
            </h4>
            <p> Branches Global Networks, LLC</p>
            <p> Effective Date: Tuesday, July 16, 2024</p>
            <h6 className="fw-bold">
              Welcome to Branches Global Networks, LLC ("we," "our," or "us").
              By using our transportation services, including white glove,
              fulfillment, last mile, tours, and stake beds trucks, you agree to
              the following terms and conditions ("Terms"). Please read these
              Terms carefully before using our services.
            </h6>
          </div>
          <div className="branches-terms-content-section">
            <div className="branches-terms-content-section-heading">
              <h6>1. Services Offered</h6>
              <p>
                Branches Global Networks, LLC provides the following
                transportation services
              </p>
              <ul>
                <li>
                  White Glove Service: Premium service including careful
                  handling, assembly, and installation of items.
                </li>
                <li>
                  Fulfillment Service: Comprehensive solutions for storing,
                  packing, and shipping goods
                </li>
                <li>
                  Last Mile Delivery: Efficient delivery of goods from the final
                  transportation hub to the end destination
                </li>
                <li>Tour Services: Transportation for tours and excursions </li>
                <li>
                  Stake Bed Trucks: Transport services using trucks with stake
                  beds for various types of cargo
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>2. Acceptance of Terms</h6>

              <p>
                By accessing and using our services, you agree to comply with
                and be bound by these Terms. If you do not agree to these Terms,
                you should not use our services.
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>3. Booking and Payment</h6>
              <ul>
                <li>
                  Booking: All services must be booked in advance. Booking can
                  be done through our website, by phone, or via email.
                </li>
                <li>
                  Payment: Payment terms are outlined in the service agreement.
                  We accept various forms of payment, including credit cards and
                  bank transfers. Full payment must be received before services
                  are rendered unless otherwise agreed upon.
                </li>
                <li>
                  Cancellations and Refunds: Cancellations must be made 74 hours
                  in advance to receive a full refund. No refunds will be issued
                  for cancellations made after this period
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>4. Service Conditions</h6>
              <ul>
                <li>
                  White Glove Service: Our white glove service includes
                  handling, assembly, and installation. Customers must provide
                  accurate details about the items to be handled.
                </li>
                <li>
                  Fulfillment Service: We manage storage, packing, and shipping.
                  Customers are responsible for providing accurate inventory and
                  shipping instructions
                </li>
                <li>
                  Last Mile Delivery: We deliver goods to the specified address.
                  Customers must ensure that the delivery address is accessible.
                </li>
                <li>
                  Tour Services: Transportation for tours is subject to
                  availability. Customers must adhere to the schedule and
                  guidelines provided
                </li>
                <li>
                  Stake Bed Trucks: These trucks are used for various cargo
                  types. Customers must ensure that the cargo is secure and
                  complies with safety regulations
                </li>
              </ul>
            </div>

            <div className="branches-terms-content-section-heading">
              <h6>5. Liability and Insurance</h6>
              <ul>
                <li>
                  Liability: We are not liable for any indirect, incidental, or
                  consequential damages arising from the use of our services.
                  Our liability is limited to the amount paid for the service in
                  question.
                </li>
                <li>
                  Insurance: We provide basic insurance coverage for all
                  shipments. Additional insurance can be purchased at the
                  customer's request.
                </li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>6. Customer Responsibilities</h6>
              <ul>
                <li>Accurate Information: Customers must provide accurate and complete information for all bookings.</li>
                <li>Compliance with Laws: Customers must comply with all applicable laws and regulations related to the transportation and handling of goods.</li>
                <li>Preparation for Service: Customers must ensure that items are properly packed and ready for transport.</li>
              </ul>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>7. Privacy and Confidentiality</h6>
              <p>
              We are committed to protecting your privacy. Please review our Privacy Policy [insert link] to understand our practices regarding the collection, use, and disclosure of your personal information.
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>8. Amendments</h6>
              <p>
              We reserve the right to amend these Terms at any time. Any changes will be posted on our website and will become effective upon posting. It is your responsibility to review these Terms periodically
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>9. Governing Law</h6>
              <p>
              These Terms shall be governed by and construed in accordance with the laws of [insert state/country]. Any disputes arising out of or related to these Terms shall be resolved in the courts of California
              </p>
            </div>
            <div className="branches-terms-content-section-heading">
              <h6>10. Contact Information</h6>
              <p>If you have any questions about these Terms, please contact us at:</p>

              <div className=" gap-5">
                <p><span className="fw-bold">Phone:</span>  833-666-3573</p>
                <p> <span className="fw-bold">Email: </span> info@bgntrasnport.com </p>
                <p><span className="fw-bold">Address:</span> 6200 Rutland Ave, Riverside Ca 92503</p>
              </div>

              <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
