import React,{useEffect} from 'react'
import "src/Styles/Whitelayout/Whitelayoutbanner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const WhiteLayoutBanner = () => {

  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, []) 

  return (
    <>
      <div className="WhitelayoutBanner">
        <img src="/new_banners_images/white_glove_banner.jpg" alt="image" />
        <div className="container-fluid">
          <div data-aos="zoom-in-up" className="WhitelayoutBanner_content">
            <h1>White Glove</h1>
            <p>When it comes to ensuring the safe delivery and precise  installation of valuable furniture or ﬁne art, rely on our highly  trained specialists for unsurpassed service, available  nationwide. With meticulous attention to detail and a  commitment to excellence, we go above and beyond to  protect your cherished possessions throughout  every step  of the journey. Trust us to handle your most valuable items  with care and expertise, delivering peace of mind along with  exceptional service.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhiteLayoutBanner
