import React from 'react';
import "src/Styles/Carreier/CareerOurvalue.css"

const CareerOurvalue = () => {
  return (
    <div className='branches-careerOur-value-main'>
        <div className='container'>
           <div className='branches-career-our-value-section'>
           <div className='branches-career-our-value-heading'> <h1>Our Values</h1></div>
           <div className='branches-career-our-value-content'>
            <div className='row'>
              <div className='col-md-4 col-12'>
                <div className='branches-career-our-card'>
                <img src="/CarriersImages/Ourvalue1.png" alt="values"/>
                  <h1>Customers First</h1>
                  <p>Our customer’s business doesn’t stop and neither do we. We power our customer’s core business and we take that very seriously.</p>
                </div>
              </div>
              <div className='col-md-4 col-12'>
                <div className='branches-career-our-card'>
                <img src="/CarriersImages/Ourvalue2.png" alt="values"/>
                  <h1>Best Answer Wins </h1>
                  <p>It doesn’t matter who you are or what you do, the best idea will always be the one that gets executed.</p>
                </div>
              </div>
              <div className='col-md-4 col-12'>
                <div className='branches-career-our-card'>
                <img src="/CarriersImages/Ourvalue3.png" alt="values"/>
                  <h1>Fueled by Passion</h1>
                  <p>Everything we do is fueled by a deep passion to solve hard problems, make a difference and accelerate the transition to a sustainable mobility future.</p>
                </div>

              </div>
            </div>
           </div>
           </div>
        </div>
    </div>
  )
}

export default CareerOurvalue