import React from "react";
import "src/Styles/Bookdemo.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 600,
  // height:600,
  bgcolor: "background.paper",
  border: "2px solid #dcdcdc",
  boxShadow: 24,
  borderRadius: 5,
  zIndex: 1,
  // p: 4,
};

const BookDemo = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="branches-model-demo">
          <div className="branches-model-demo-main">
            <div className="row">
              <div className="col-4 d-md-flex d-none">
                <div className="branches-model-demo-left">
                  <img src="/serviceImages/modelimage1.png" alt="modeldemo" />
                </div>
              </div>
              <div className="col-md-8 col-12">
                <div className="branches-model-demo-right-section">
                  <div className="branches-model-demo-right-icons">
                    <IoMdClose onClick={handleClose} />
                  </div>

                  <div className="branches-model-form-content">
                    <div className="brnaches-model-from-heading">
                      <h6>Book Your Appointment </h6>
                      <p>
                        A few details and we will get back to you as soon as
                        possible.&nbsp;
                      </p>
                    </div>

                    <form>
                      <div className="brnaches-model-from-section">
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3 form-group branches-form-input-field">
                              <label className="form-label">First Name*</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3 form-group branches-form-input-field">
                              <label className="form-label">Last Name*</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 form-group branches-form-input-field">
                          <label className="form-label">Email address*</label>
                          <input type="email" className="form-control" />
                        </div>
                        <div className="mb-3 form-group branches-form-input-field">
                          <label className="form-label">Company*</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="mb-3 form-group branches-form-input-field">
                          <label className="form-label">Job title</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <button
                        className="branchesglobalnetworks_global_button mt-3 w-100"
                        type="button"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default BookDemo;
