import React,{useEffect} from 'react'
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import FulfillmentBanner from 'src/Fulfillment/FulfillmentBanner';
import FulfillmentExtended from 'src/Fulfillment/FulfillmentExtended';
import FulfillmentSlider from 'src/Fulfillment/FulfillmentSlider';
import FullfilmentServiceCard from 'src/LastMile/FullfilmentServiceCard';




const Fulfillment = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Navbar />
      <FulfillmentBanner/>
      {/* <FulfillmentExtended/> */}
      {/* <FulfillmentSlider/> */}
      <FullfilmentServiceCard />
      <Footer />
    </>
  )
}

export default Fulfillment
