import React from 'react'
import CareerJob from 'src/Carrier/CareerJob'
import CareerOurlatest from 'src/Carrier/CareerOurlatest'
import CareerOurvalue from 'src/Carrier/CareerOurvalue'
import Carrierbanner from 'src/Carrier/Carrierbanner'
import Footer from 'src/Commons/Footer'
import Navbar from 'src/Commons/Navbar'
const Carrier = () => {
  return (
   <>
    <Navbar/>
    <Carrierbanner/>
    <CareerOurvalue/>
    <CareerJob/>
    <CareerOurlatest/>
    <Footer/>
   </>
  )
}

export default Carrier