import React,{useEffect} from "react";
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import LastMileMiddleSec from "src/LastMile/LastMileMiddleSec";
import EcommersBanner from "src/Ecommerce/EcommersBanner";
import EcommerceOptimazation from "src/Ecommerce/EcommerceOptimazation";


const Ecommerce = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Navbar />
      <EcommersBanner />
      <EcommerceOptimazation/>
      <LastMileMiddleSec />
      <Footer />
    </>
  );
};

export default Ecommerce;
