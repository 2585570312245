import React,{useEffect} from "react";
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import LastMileMiddleSec from "src/LastMile/LastMileMiddleSec";
import DropShipBanner from "src/Dropship/DropShipBanner";
import DropShipOptimazation from "src/Dropship/DropShipOptimazation";

const DropShip = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <Navbar />
      <DropShipBanner />
      <DropShipOptimazation/>
      <LastMileMiddleSec />
      <Footer />
    </>
  );
};

export default DropShip;
