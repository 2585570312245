import React,{useEffect} from "react";
import "src/Styles/LastMile/LastMilebanner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


const LastMileBanner = () => {

  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <>
      <div className="LastMileBanner">
        <img src="/new_banners_images/last_mile_banner.png" alt="image" />
        <div className="container-fluid">
          <div  data-aos="zoom-in-up" className="LastMileBanner_content">
            <h1>Last Mile</h1>
            <p>
              Electrify your last mile experience with faster shipping and
              enhanced delivery options for products of all sizes, from small
              parcels to large, bulky items. Provide your shoppers with
              unparalleled same-day convenience
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastMileBanner;
