import React from "react";
import "src/Styles/our_value.css";

function OurValue() {
  return (
    <div>
      <div className="branches_our_value_main">
        <div className="container">
          <h2>Our Values</h2>
          <h6>
            At Global Networks, our core values are the foundation of everything
            we do. These values guide us in delivering top-tier supply chain
            solutions and exceptional white-glove delivery services to our
            clients.
          </h6>

          <div className="row mt-5 g-3">
            <div  data-aos="zoom-in-up" className="col-lg-3 col-md-6 col-12">
              <div className="branches_our_value_box">
                <div className="branches_our_value_image_main">
                  <img src="/images/our_value1.png" alt="image" />
                </div>
                <h4>Customer Success</h4>
                <p>
                  We are dedicated to advancing our customers' success. By
                  understanding their unique needs and challenges, we provide
                  innovative solutions that propel their businesses forward.
                </p>
              </div>
            </div>
            <div  data-aos="zoom-in-up" className="col-lg-3 col-md-6  col-12">
              <div className="branches_our_value_box">
                <div className="branches_our_value_image_main">
                  <img src="/images/our_value2.png" alt="image" />
                </div>
                <h4>Succeed Together</h4>
                <p>
                  Collaboration and teamwork are at the heart of our approach.We
                  believe in working closely with our clients and partners to
                  achieve shared goals and celebrate collective successes.
                </p>
              </div>
            </div>
            <div  data-aos="zoom-in-up" className="col-lg-3 col-md-6  col-12">
              <div className="branches_our_value_box">
                <div className="branches_our_value_image_main">
                  <img src="/images/our_value3.png" alt="image" />
                </div>
                <h4>Exceeding Boundaries</h4>
                <p>
                  We continuously strive to exceed expectations and push the
                  boundaries of what's possible. Through innovation and a
                  relentless pursuit of excellence, we aim to deliver
                  outstanding results.
                </p>
              </div>
            </div>
            <div  data-aos="zoom-in-up" className="col-lg-3 col-md-6  col-12">
              <div className="branches_our_value_box">
                <div className="branches_our_value_image_main">
                  <img src="/images/our_value4.png" alt="image" />
                </div>
                <h4>Deliver on Promises</h4>
                <p>
                  Integrity and accountability are paramount. We keep our
                  promises and ensure that our actions consistently reflect our
                  words, building trust with our clients and partners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurValue;
